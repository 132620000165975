<script>
import UIInput from '@components/uiinput';
import { mapMutations, mapGetters } from 'vuex';
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { isStrongPassword } from '@commons/validations';
import Recaptcha from '@components/Recaptcha';
import http from '@commons/http';

export default {
	name: 'LoginNewPass',
	components: {
		UIInput,
		Recaptcha
	},
	data() {
		return {
			notification: false,
			form: {
				novaSenha: "",
				confirmaNovaSenha: ""
			},
			loading: false,
		}
	},
	validations: {
		form: {
			novaSenha: {
				required,
				minLength: minLength(8),
				isStrongPassword
			},
			confirmaNovaSenha: {
				sameAsPassword: sameAs('novaSenha'),
				required,
				minLength: minLength(8),
				isStrongPassword
			}
		}

	},
	computed: {
		...mapGetters('senha', [
			'getEmailMasked',
			'getCpf',
			'getReseId',
		]),
	},

	mounted() {
		if (!this.getReseId || this.getReseId == 'null') {
			this.goto();
		}
	},

	methods: {
		...mapMutations('senha', [
			'setEmailMasked',
			'setCpf'
		]),
		goto() {
			this.$router.push({ name: 'login.home' });
		},
		sucess_page() {
			this.$router.push({ name: 'login.recover_success' });
		},
		async handleRenewSubmit() {
			this.$v.form.$touch();

			if (this.$v.form.$pending || this.$v.form.$error) return;

			let data_params = new URLSearchParams(
				Object.entries({
					new_pass: this.form.novaSenha,
					rese_id: this.getReseId
				})
			);
			this.loading = true;

			try {
				const captcha = await this.$refs.grecaptcha.generate();

				data_params.append('captcha', captcha);
			} catch (error) {

				console.log(error);
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: `Recaptcha inválido!`,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
				return;
			}
			http.post('/v1/auth/senha/updatepass', data_params).then(({ data }) => {
				this.loading = false;
				if (this.notification) this.notification.close();

				if (data.status == 'success') {
					this.sucess_page();
				} else {
					this.notification = this.$buefy.notification.open({
						indefinite: true,
						queue: false,
						message: data.error,
						position: 'is-top',
						type: 'is-danger',
						hasIcon: true
					});
				}
			}, ({ data }) => {
				this.loading = false;
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: data.message,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
			});
		}
	},

}
</script>


<template>
	<div class="container">
		<div class="has-text-centered">
			<h2 class="title is-1">
				Insira sua nova senha abaixo:
			</h2>
		</div>

		<div class="columns mt-5">
			<div class="column is-6-desktop is-offset-3-desktop is-4-fullhd is-offset-4-fullhd">
				<div class="columns is-multiline is-text-centered">
					<form
						class="column is-12"
						@submit.prevent="handleRenewSubmit"
					>
						<div class="column is-12">
							<UIInput
								id="nova_senha"
								v-model="form.novaSenha"
								type="password"
								label="Sua nova senha"
								:validation="$v.form.novaSenha"
								:error="{
									minLength: '*A quantidade de caracteres é menor que 8.',
									invalid: '*Campo inválido.',
									required: '*Campo obrigatório.',
									strong: '*A senha deve conter números e letras maiúsculas e minúsculas.'
								}"
								required
							/>

							<div class="columns">
								<div class="column is-paddingless">
									<p class="paragraph pt-4 pl-5">
										A senha deve conter: 8 digitos, letras minúsculas e<br>
										maiúsculas, números e algum caracter especial
									</p>
								</div>
							</div>
						</div>

						<div class="column is-12">
							<UIInput
								id="confirma_nova_senha"
								v-model="form.confirmaNovaSenha"
								type="password"
								label="Confirme sua nova senha"
								:validation="$v.form.confirmaNovaSenha"
								:error="{
									minLength: '*A quantidade de caracteres é menor que 8.',
									invalid: '*Campo inválido.',
									required: '*Campo obrigatório.',
									strong: '*A senha deve conter números e letras maiúsculas e minúsculas.',
									sameAs: '*A senha e a confirmação devem ser a mesma.'
								}"
								required
							/>
						</div>

						<div class="column is-12 has-text-centered mt-4">
							<button class="button is-primary is-rounded">
								Confirmar
							</button>
						</div>
					</form>

					<div class="column is-12 has-text-centered mt-0">
						<button
							class="button is-primary is-rounded is-outlined"
							@click="goto"
						>
							Voltar para o login
						</button>
					</div>
				</div>
			</div>
		</div>
		<b-loading
			v-model="loading"
			:is-full-page="true"
			:can-cancel="false"
		/>

		<Recaptcha ref="grecaptcha" />
	</div>
</template>

<style scoped>
.notification {
	background-color: transparent;
}
</style>
