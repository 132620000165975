var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"columns mt-5"},[_c('div',{staticClass:"column is-6-desktop is-offset-3-desktop is-4-fullhd is-offset-4-fullhd"},[_c('div',{staticClass:"columns is-multiline is-text-centered"},[_c('form',{staticClass:"column is-12",on:{"submit":function($event){$event.preventDefault();return _vm.handleRenewSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"nova_senha","type":"password","label":"Sua nova senha","validation":_vm.$v.form.novaSenha,"error":{
								minLength: '*A quantidade de caracteres é menor que 8.',
								invalid: '*Campo inválido.',
								required: '*Campo obrigatório.',
								strong: '*A senha deve conter números e letras maiúsculas e minúsculas.'
							},"required":""},model:{value:(_vm.form.novaSenha),callback:function ($$v) {_vm.$set(_vm.form, "novaSenha", $$v)},expression:"form.novaSenha"}}),_vm._m(1)],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"confirma_nova_senha","type":"password","label":"Confirme sua nova senha","validation":_vm.$v.form.confirmaNovaSenha,"error":{
								minLength: '*A quantidade de caracteres é menor que 8.',
								invalid: '*Campo inválido.',
								required: '*Campo obrigatório.',
								strong: '*A senha deve conter números e letras maiúsculas e minúsculas.',
								sameAs: '*A senha e a confirmação devem ser a mesma.'
							},"required":""},model:{value:(_vm.form.confirmaNovaSenha),callback:function ($$v) {_vm.$set(_vm.form, "confirmaNovaSenha", $$v)},expression:"form.confirmaNovaSenha"}})],1),_vm._m(2)]),_c('div',{staticClass:"column is-12 has-text-centered mt-0"},[_c('button',{staticClass:"button is-primary is-rounded is-outlined",on:{"click":_vm.goto}},[_vm._v(" Voltar para o login ")])])])])]),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('Recaptcha',{ref:"grecaptcha"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-text-centered"},[_c('h2',{staticClass:"title is-1"},[_vm._v(" Insira sua nova senha abaixo: ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-paddingless"},[_c('p',{staticClass:"paragraph pt-4 pl-5"},[_vm._v(" A senha deve conter: 8 digitos, letras minúsculas e"),_c('br'),_vm._v(" maiúsculas, números e algum caracter especial ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-12 has-text-centered mt-4"},[_c('button',{staticClass:"button is-primary is-rounded"},[_vm._v(" Confirmar ")])])}]

export { render, staticRenderFns }